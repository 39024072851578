/*GLOBAL*/
:root {
  --header-height: 8.5vh;
  --dark-primary: #0C0C0A;
  --light-primary: rgb(37, 37, 29);
  --detail-color: #DCC67A;
  --button-color: white;
  --h1-mobile: 4rem;
  --h2-mobile: 3rem;
  --h3-mobile: 1.2rem;
}
html {
  background-color: black;
}
body {
  overflow-x: hidden;
}
* {
  font-family: 'Quicksand', sans-serif;
  color: var(--detail-color)
}
a {
  text-decoration: none;
}
ul{
  margin: 0;
  padding: 0;
}
li {
  list-style-type: none;
}
h1 {
  font-family: 'Josefin Sans', sans-serif;
  font-size: var(--h1-mobile);
  margin: 0;
}
h2 {
  font-size: var(--h2-mobile);
  border-bottom: calc(var(--h2-mobile) / 10) solid var(--detail-color);
  color: var(--detail-color);
  width: max-content;
  margin: 50px auto;
  font-weight: 400;
  font-family: 'Josefin Sans', sans-serif;
}
h3 {
  font-size: var(--h3-mobile);
  color: var(--detail-color);
  font-weight: 400;
  width: max-content;
  border-bottom: calc(var(--h3-mobile) / 10) solid var(--detail-color);
  margin: 0 auto;
  font-family: 'Josefin Sans', sans-serif;
  text-transform: uppercase;
}
p {
  color: var(--detail-color);
  margin-bottom: 0;

}
strong {
  color: var(--dark-primary)
}
@media screen and (min-width: 1000px) {
  p {
    font-size: 1.2rem;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}
main {
  position: relative;
}
.slide-out {
  transform: (translateX(-100px));
}
/*HEADER*/
.header {
  height: var(--header-height);
  background: linear-gradient(var(--dark-primary), transparent);
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  min-width: 100%;
  z-index: 4;
}
.header-invisible {
  background: transparent;
}
.header-visible {
  background: linear-gradient(var(--dark-primary), transparent);

}
.hamburger {
  background-color: transparent;
  border: none;
  width: 75px;
  /* height: 100%; */
  margin-left: auto;
  margin-right: 2%;
  cursor: pointer;
  position: relative;
  transition: all 0.2s;
}

.hamburger-line {
  width: 100%;
  height: 5px;
  background-color: var(--button-color);
  border-radius: 20px;
  margin: 15% 0;
  transition: all .2s;
  position: relative;
}
.close-top {
  transform: translateY(14px) rotate(45deg);
}

.close-middle {
  transform: translateX(-20px);
  background: transparent; 
}
.close-bottom {
  transform: translateY(-14px) rotate(-45deg);
}
.desktop-nav {
  margin: 0 auto;
  width: 100%;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.desktop-nav ul {
  display: flex;
  justify-content: space-evenly;
  padding: 0;
}
.desktop-nav li {
  color: var(--button-color);
  list-style-type: none;
  cursor: pointer;
}
.nav-link {
  color: var(--button-color);
  cursor: pointer;
}
.nav-link:hover {
  color: var(--detail-color);
}
.current-scroll {
  color: var(--detail-color);
}
/*MENU*/
.mobile-menu {
  background-color: var(--dark-primary);
  position: fixed;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mobile-menu ul {
  padding: 0;
}
.mobile-menu li {
  list-style-type: none;
  margin: 1em auto;
  font-size: 2rem;
  width: fit-content;
}

.menu-expand-enter {
  opacity: 0;
}
.menu-expand-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}
.menu-expand-exit {
  opacity: 1;
}
.menu-expand-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}
/*STARTPAGE*/
.no-scroll {
  overflow: hidden;
}
.startpage-wrapper {
  background-color: var(--dark-primary);
  background-image: linear-gradient(rgba(12, 12, 10, 0.5), rgba(12, 12, 10, 1)), url('./img/boxing-gloves-small.png');
  background-position: left top;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.forca-logo {
  margin: var(--header-height) auto 2rem auto;
  height: 60vh;
  /* width: 40vw; */
}
.button-wrapper {
  width: 100%;  
  display: flex;
  flex-direction: column;
  align-items: center;
}

.club-link {
  display: block;
  width: 100%;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.club-link p{
  margin-top: 0;
}
.button-club {
  width: 150px;
  min-height: 70px;
  border-radius: 15px;
  border: 3px solid var(--detail-color);
  background: var(--dark-primary);
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  position: relative;
}
.button-club:hover {
  background-color: var(--detail-color);
  color: var(--dark-primary);
}
.button-club:hover p {
  color: var(--dark-primary);
}
.button-ursvik {
  margin-bottom: auto;
  margin-top: 5%;
}

@media screen and (min-width: 700px){
  .startpage-wrapper {
    height: 100vh;
    background-image: linear-gradient(rgba(12, 12, 10, 0.5), rgba(12, 12, 10, 1)), url('./img/boxing-gloves-big-cropped.jpg');
  }
  .forca-logo {
    height: 70vh;
    /* width: 50vw; */
  }
  .button-wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 15vh;
  }
  .button-club {
    width: 200px;
    height: 80%;
    font-size: 1.3rem;
  }
  .button-ursvik {
    margin: 0;
    margin-right: 15%;
  }
  .button-solna {
    margin: 0 auto;
  }
}
@media screen and (min-width: 1400px){
  .button-club {
    width: 280px;
    height: 110px;
    font-size: 1.8rem;
    border-radius: 20px;

  }
}
.scroll-down-button {
  background-color: transparent;
  border: none;
  width: 15%;
  max-width: 90px;
  margin: 1rem 0;
  cursor: pointer;
}
.down-arrow {
  width: 100%;
}
.transparent {
  opacity: 0;
}
.solna-overlay {
  background-color: var(--dark-primary);
  background: linear-gradient(90deg, rgba(12,12,10,1) 40%, rgba(12,12,10,0.49763655462184875) 100%), url('./img/instructor.png');
  background-position: right bottom;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  position: absolute;
  width: 100vw;
}
.overlay-text {
  width: 30%;
  line-height: 1.5;
}
.solna-overlay p {
  margin: 15% 15%;
}
.ursvik-overlay {
  background-color: var(--dark-primary);
  background: linear-gradient(0deg, rgba(12,12,10,1) 0%, rgba(12,12,10,0.49763655462184875) 20%), linear-gradient(270deg, rgba(12,12,10,1) 45%, rgba(12,12,10,0.4) 100%), url('./img/thewalk.png');
  background-size: cover;
  min-height: 100vh;
  position: absolute;
  width: 100vw;
}
.ursvik-overlay p {
  margin: 15% 55%;
  width: 30%;
  text-align: right;
}
.club-transition-enter {
  opacity: 0;
}
.club-transition-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}
.club-transition-exit {
  opacity: 1;
}
.club-transition-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}
/*ABOUT*/
.about-wrapper {
  background-image: linear-gradient(rgba(12, 12, 10, 1), rgba(12, 12, 10, 0.8), var(--light-primary)), url('./img/slide3.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 0;
}
.about-text {
  max-width: 35ch;
  line-height: 1.5;
}
.about-text-bottom {
  margin-bottom: 2rem;
}
@media screen and (min-width: 1000px) {
  .about-wrapper {
    background: linear-gradient(0deg, rgba(37, 37, 29, 1) 0%, rgba(37, 37, 29, 0) 20%), linear-gradient(180deg, rgba(12,12,10,1) 0%, rgba(12,12,10,0.49763655462184875) 20%), linear-gradient(90deg, rgba(12,12,10,0.49763655462184875) 0%, rgba(12,12,10,1) 50%), url('./img/slide3.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .about-wrapper h2 {
    grid-column: 2;
  }
  .about-text {
    grid-column: 2;
    max-width: 55ch;
  }
}

/*STYLES*/
.cards-wrapper {
  background-color: var(--dark-primary);
  background: linear-gradient(var(--light-primary), var(--dark-primary) 35%);
  padding: 2rem 0;
}
.cards-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2em 0;

}
.card {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;
}
@media screen and (min-width: 775px) {
  
  .cards-div {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
  }
  .card {
    width: 30%;
    
  }
}
.card-img{
  width: 100px;
}
.card-text {
  text-align: center;
  margin: 0;
  width: 80%;
}

/*PERSONAL*/
.personal-wrapper {
  background-color: var(--dark-primary);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.personal-text {
  max-width: 75vw;
  margin: 2rem 0;
}
/*PICTURES*/
.pictures-wrapper {
  background-color: var(--light-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gallery {
  margin: 5em auto;
  max-width: 70%;
  /* border: 2px solid var(--detail-color); */
}
/*CONTACT*/
.contact-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 0;
  background: linear-gradient(var(--dark-primary), rgba(12, 12, 10, 0.8)), url('./img/kickboxer.png');
  background-position: left center;
}
.contact-card {
  margin: 5% auto;
}
@media screen and (min-width: 830px) {

  .contact-wrapper {
    background: linear-gradient(var(--dark-primary), rgba(12, 12, 10, 0.8)), url('./img/kickboxer-large.jpg');
    background-position: center;
  }
}

/*CLUBS*/

/*SOLNA*/
.solna {
  background-image: linear-gradient(rgba(12, 12, 10, 0.7), rgba(12, 12, 10, 1)), url('./img/hands-small.png');
  background-position: center;
  justify-content: center;
  height: 100vh;
}
.ursvik {
  background-image: linear-gradient(rgba(12, 12, 10, 0.7), rgba(12, 12, 10, 1)), url('./img/hands-small.png');
  background-position: center;
  justify-content: center;
  height: 100vh;
}
@media screen and (min-width: 700px) {
  .solna, .ursvik {
    background-image: linear-gradient(rgba(12, 12, 10, 0.7), rgba(12, 12, 10, 1)), url('./img/hands-1024x683.jpg');
    background-position: center;
    justify-content: center;
    height: 100vh;
  }
}
.club-heading {
  text-transform: uppercase;
}
.club-logo {
  margin: 0;
}
.schedule {
  background-color: var(--light-primary);
  background: linear-gradient(var(--light-primary) 70%, var(--dark-primary) 100%);
  padding: 2em 0;
}
.schedule-wrapper {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 2rem;
}
.schedule-week {
  display: flex;
}
.schedule-day {
  background-color: var(--dark-primary);
  cursor: pointer;
  font-size: 1.5rem;
  padding: .25rem;
  margin-right: .5rem;
  border-radius: 5px 5px 0 0;
  width: 15%;
  text-align: center;
}
.selected-day {
  background-color: var(--detail-color);
  color: var(--dark-primary);
}
.day-wrapper {
  background-color: var(--light-primary);
  border: 2px solid var(--detail-color);
  color: var(--dark-primary);
  padding: 1rem;
}

.activity-wrapper {
  background-color: var(--detail-color);
  border-radius: 15px;
  padding: 1rem;
  width: 85%;
}
.activity-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
  align-items: center;
}
.time-wrapper li {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  align-items: center;
  color: var(--dark-primary);
  font-size: 1.2rem;
}
@media screen and (min-width: 1000px) {
  .schedule-wrapper {
    width: 99%;
  }
  .schedule-week {
    width: 100%;
  }
  .schedule-day{
    flex: 1;
    margin: 0;
    border-radius: 0;
    cursor: default;
    border: 2px solid var(--detail-color);
  }
  .selected-day{
    background-color: var(--dark-primary);
    color: var(--detail-color);
  } 
  .activity-wrapper {
    width: auto;
    flex: 1;
  }
  .activity-list {
    flex: 1;
    align-items: stretch;
    padding-left: 10px;
    padding-right: 10px;
    border: 2px solid var(--detail-color);
    border-top: none;
    border-bottom: none;
  }
  .time-wrapper li {
    font-size: 1rem;
  }
}
.full-week {
  background-color: var(--light-primary);
  border: 2px solid var(--detail-color);
  border-top: none;
  color: var(--dark-primary);
  display: flex;
}
.price-wrapper {
  background-color: var(--dark-primary);
  padding: 2rem 0;
}
.prices-ul {
  width: fit-content;
  margin: 2rem auto;
}
.price-listing {
  margin-top: 1rem;
  font-size: 1.2rem;
}
.trainers {
  background: var(--dark-primary);
}
/* Sommarschema! */
.news-backdrop {
  background-color: #0c0c0acf;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 10;
}
.news {
  position: relative;
  background: var(--dark-primary);
  border: 3px solid var(--detail-color);
  padding: 10px;
  width: 65%;
  margin: 4rem auto;
  min-height: 400px;
}
.news div {
  margin: 0 auto;
  padding: 30px;
  width: fit-content;
}
.news span {
  text-decoration: underline;
}
.close-news {
  position: absolute;
  border-radius: 50%;
  border: 2px solid var(--detail-color);
  background-color: var(--dark-primary);
  width: 50px;
  height: 50px;
  font-size: 2rem;
  left: -25px;
  top: -25px;
  cursor: pointer;
}
.news-list{
  padding: 1rem;
  padding-left: 40px;
}
.news-list li{
  list-style-type: circle;
  margin-bottom: 5px;
}
.schedule h3 {
  margin-bottom: 20px;
  border-bottom: none;
}
.schedule h4 {
  text-align: center;
}
.carousel .slide img {
  max-height: 500px;  /* change this to whatever you want */
  width: auto;
}

.ursvik-summer {
  background-color: var(--light-primary);
  background: linear-gradient(var(--light-primary) 70%, var(--dark-primary) 100%);
  padding: 2em 0;
}
.ursvik-summer div {
  border: 3px solid var(--detail-color);
  padding: 40px;
  width: 65%;
  margin: 0 auto;
}
.summer-prices {
  margin: 0 auto 60px auto;
}
.summer-prices p {
  text-align: center;
}

@media screen and (max-width: 700px) {
  .news h2 {
    font-size: 2.2rem;
  }
  .news h3 {
    font-size: 1rem;
  }
  .news {
    width: 80%;
    margin: 2rem auto;
  }
  .news div {
    padding: 0;
    padding-top: 10px;
  }
}

.rules-wrapper {
  background-color: var(--dark-primary);
  padding-top: 1px;
  display: flex;
  flex-direction: column;
  padding: 20px 100px;
}

.rules-heading {
  font-size: 1.6rem;
}

.rules-section {
  margin-top: 80px;
}
.rules-section h3 {
  margin: 0;
  margin-bottom: 10px;
}
.rules-section li {
  list-style-type: disc;
  padding-left: 10px;
  margin-bottom: 5px;
}

.rules-footer {
  text-align: center;
  margin-top: 100px;
  font-weight: 800;
}

.private-heading {
  font-size: 1.9rem;
}